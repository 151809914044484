<template>
    <footer class="text-center">
        Copyright Takerman 2024
    </footer>
</template>
<script lang="js">
export default {
    data() {
        return {
        }
    }
}
</script>