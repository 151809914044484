<template>
  <div class="text-center">
    <h2>Welcome to publisher</h2>
    <p>That's a website for publishing to media websites and for now is for just an internal use.</p>
  </div>
</template>

<script lang="js">
export default {
}
</script>
